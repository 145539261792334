import React from 'react'
import { Link } from 'react-router-dom'
import { useStateContext } from '../components/Constants/ContextProvider'

const Burger = () => {

  const {currentUser} = useStateContext()

  return (
    <aside className='fixed z-[10000000000] w-[60%] p-4 bg-[#46175a] h-full top-0'>
      <div>
        <img src="assets/LOGO MATRIX 2.0.png" alt="" className=' w-2/4 max-md:w-[200px]'/>
      </div>
      
      <div className='flex flex-col gap-y-5 mt-[40px]'>
      <Link to={'/profile'}>
      <img src={currentUser !== null ? `${currentUser.photoURL}` : 'https://i.pinimg.com/564x/f1/0f/f7/f10ff70a7155e5ab666bcdd1b45b726d.jpg'} alt="profile" className='h-10 w-10 rounded-full'/>
      </Link>
      <Link to="/Home" className="text-white  hover:text-[#bd71e3]  text-[1.5em]" >Home</Link>
      <Link to="/Secretariat" className="text-white  hover:text-[#bd71e3]  text-[1.5em]">Secretariat</Link>


      <Link to="/Committees" className="text-white  hover:text-[#bd71e3] text-[1.5em]"  >Committees</Link>

      <Link to="/Sponsors" className="text-white hover:text-[#bd71e3] text-[1.5em]" >Sponsor Us</Link>
      <Link to="/Contact" className="text-white hover:text-[#bd71e3] text-[1.5em]"> Contact Us</Link>
      
      </div>
      </aside>
  )
}

export default Burger
