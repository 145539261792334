import React from 'react'
import styled from 'styled-components'

const Previous = () => {
  return (
   <PreviousContainer className='text-center flex justify-center flex-col w-full'>
 <h1 className='text-[6em] max-sm:text-[2.7em] md:text-[6em]  text-[#bd71e3] text-center font-semibold'>Previous Sponsors</h1>
    <p className='text-[1em] md:text-[1.3em] my-3 text-center max-sm:w-[80%] text-white w-[65%] mx-auto'>The event was made possible only through the exemplary work of the event’s sponsors Gillette, दैनिक जागरण, Fresca and co-sponsors Chocolypse, Delhi School Of Photography, AURA, U.K.N. Snacks, Wang Ramen, Sid & Som, Lahore Bakers, DU Memeswala, DUCOMPETETION,DELHI UNIVERSITY CLUB, Escape, Greenlight Events, B. Tech. Paani Puri Wali & DU Ofﬁcial.</p>

    <p className='text-[1em] md:text-[1.3em] mb-3 text-center max-sm:w-[80%] text-white w-[65%] mx-auto'>Their partnership not only provided us with ﬁnancial aid but has also inspired us to further our efforts and strive for even greater success. With their support, we are conﬁdent in our ability to continue making a difference in the lives of those we serve.</p>

    

    <section className='container '>
<img src="assets/amul1.png" alt=""  className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]'/>
<img src="assets/career_launcher.png" alt=""  className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]'/>
{/* <img src="assets/dainikk.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' /> */}
<img src="assets/frescaa.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
<img src="assets/giani.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
<img src="assets/gillette.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
<img src="assets/jamboree.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
<img src="assets/maac.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
<img src="assets/nescafe.png" alt="" className='  m-auto max-sm:w-[140px] w-[160px] md:w-[180px]  rounded-[50%]' />
      </section>
      
   </PreviousContainer>
  )
}

const PreviousContainer = styled.div`
  
 .container{
  margin-top: 50px !important;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 3em;
    margin: auto;
  }
 
  @media screen and (max-width:788px){
    .container{
      gap:2em ;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    
    }
  }
  @media screen and (max-width:468px){
    .container{
      gap:1.7em;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    
    }
  }

`

export default Previous
