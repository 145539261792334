import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import About from '../components/home/About';
import Glimpses from '../components/home/Glimpses';
import Footer from '../layout/Footer';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../components/fire base/FireBase';
import { useStateContext } from '../components/Constants/ContextProvider';
import Committees from '../components/home/Committees';





const Home = () => {

  interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }

  //timer 
  const calculateTimeLeft = (): TimeLeft => {
    const difference = +new Date('2024-11-17T20:00:00') - +new Date();
    let timeLeft: TimeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const navigate = useNavigate();
  const {currentUser, studentData, umungData} = useStateContext();

  const SigninWithGoogle = async()=>{
    signInWithPopup(auth,new GoogleAuthProvider())
    .then(response =>{
        navigate('/Form');
    })
    .catch(error =>{
        console.log(error);
    })
}

  return (

    <HomeContainer>
        <section className="home" id="home">
            <div className="max-width">
                <img src="assets/MUN5.jpg" className='absolute h-screen w-screen object-cover' alt="" />
                <div className="home-content">
                    <div className="text-2 "><span className="mind">MINDS IN MOTION</span><span className="text-[#d84bee]">.</span></div>
                    <div><span className="second">THIRD EDITION</span></div>
                
            
        <div className='z-[10000] flex max-sm:flex-col flex-row gap-6 mt-8 md:gap-7'>

      
{currentUser === null &&
<>
 <button
  type="submit"

  className="flex justify-center gap-2 items-center mx-auto shadow-xl text-[1.5em] bg-[#321237] backdrop-blur-md 3xl:font-medium isolation-auto border-[#321237] before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-xl before:bg-[#863393] hover:text-[#ededed] before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-4 py-2 overflow-hidden border-2 hover:border-[#863393] rounded-xl group text-white"
  onClick={()=>SigninWithGoogle()}

>
  <p className='text-[1.2em]'> Sign Up</p>
</button>

 <button
  type="submit"

  className="flex justify-center gap-2 items-center mx-auto shadow-xl text-[1.5em] bg-[#321237] backdrop-blur-md 3xl:font-medium isolation-auto border-[#321237] before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-xl before:bg-[#863393] hover:text-[#ededed] before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-7 py-2 overflow-hidden border-2 hover:border-[#863393] rounded-xl group text-white"
  onClick={()=>SigninWithGoogle()}
>
  <p className='text-[1.2em] '> Log In</p>
</button>
</>
}

           

           

            {/* register now */}
{(studentData !== null && umungData === null) && <button
  type="submit"
  className=" flex justify-center gap-5 items-center mx-auto shadow-xl text-[1.5em] bg-[#321237] backdrop-blur-md 3xl:font-medium isolation-auto border-[#321237] before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-xl before:bg-[#863393] hover:text-[#ededed] before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-5 py-3 overflow-hidden border-2 hover:border-[#863393] rounded-xl group text-white"
  onClick={()=>navigate('/register')}
>
  <p className='text-[1.2em] '> Register Now</p>
</button>}
             {/*already  register  */}
           {umungData !== null &&  <button
  type="submit"
  className="justify-center gap-5 flex items-center mx-auto shadow-xl text-[1.5em] bg-[#321237] backdrop-blur-md 3xl:font-medium isolation-auto border-[#321237] before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-xl before:bg-[#863393] hover:text-[#ededed] before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-5 py-3 overflow-hidden border-2 hover:border-[#863393] rounded-xl group text-white"

>
  <Link to="#" className='text-[1.2em] '> Already Registered </Link>
</button>}

        </div>
        </div>
                <div className="colored-cover"></div>
            </div>
        </section>
<About />
<div className=" flex justify-center items-center text-white mt-16 flex-col gap-2">
  <h1 className='max-sm:text-[2.2em] text-[3.5em] text-[#bd71e3]'>Conference starts in:</h1>
      <div className="text-center">

        <div className="grid grid-cols-4 gap-4  max-sm:text-[2.8em] text-6xl font-semibold">
          <div className="flex flex-col">
            <span>{timeLeft.days}</span>
            <span className="text-3xl max-sm:text-[.4em] text-[white]">Days</span>
          </div>
          <div className="flex flex-col">
            <span>{timeLeft.hours}</span>
            <span className="text-3xl max-sm:text-[.4em] text-[white]">Hours</span>
          </div>
          <div className="flex flex-col">
            <span>{timeLeft.minutes}</span>
            <span className="text-3xl max-sm:text-[.4em] text-[white]">Minutes</span>
          </div>
          <div className="flex flex-col">
            <span>{timeLeft.seconds}</span>
            <span className="text-3xl max-sm:text-[.4em] text-[white]">Seconds</span>
          </div>
        </div>

      </div>
    </div>
<Committees/>
<Glimpses />
<Footer/>
</HomeContainer>

  )
}


const HomeContainer = styled.div`
background-color: #290d35;

.home{
    display: flex;
    height: 100vh;
    color: #f8efef;
    min-height: 50vh;
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Ubuntu', sans-serif;
}
.home .max-width{
  width: 100%;
  display: flex;
}
.home-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.home .home-content .text-2{
    font-size: 13vh;
    font-weight: 800;
    z-index: 999;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.home .home-content .second {
    margin: 0px;
    font-size: 2.4rem;
    color: #d84bee;
    z-index: 99;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-weight: 900;
}
.colored-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000;
    opacity: 0.6;
    /* Adjust the opacity as needed */
}

@media screen and (max-width:768px){
    
.home .home-content .text-2{
    font-size: 7.5vh;
    font-weight: 800;
    z-index: 999;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.home .home-content .second {
    margin: 0px;
    font-size: 2em;
    color: #d729c6;
    z-index: 99;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-weight: 900;
}
}
`;
export default Home
