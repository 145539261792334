import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useStateContext } from '../components/Constants/ContextProvider';

interface NavbarProps {
  open: React.Dispatch<React.SetStateAction<boolean>>;
  isopen: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ open, isopen }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const {currentUser} = useStateContext()

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <NavbarContainer className="w-full top-0 " scrollNav={scrollNav}>
      <nav className="flex justify-between items-center p-1 gap-10 md:max-lg:mt-6">
        {/* Logo */}
        <div className="flex justify-between items-center w-full mx-3">
          <Link to="/Home">
            <img
              src="assets/newlogo.png"
              alt="Logo"
              className="w-[150px] max-md:w-[130px] max-lg:w-[180px]"
            />
          </Link>

          <button
            id="burgerButton"
            onClick={() => open(!isopen)}
            className="p-2 text-2xl text-white rounded-md focus:outline-none z-50 md:hidden max-md:block"
          >
            ☰
          </button>
        </div>

        {/* Navigation list */}
        <div className="flex flex-row gap-[4rem] justify-center text-center items-center max-md:hidden w-full">
          <ul className="flex flex-row justify-center items-center space-x-5">
            <li>
              <Link to="/Home" className="text-white hover:text-[#bd71e3] text-xl md:text-[1.2rem] font-ubuntu font-semibold md:font-bold">
                Home
              </Link>
            </li>
            <li>
              <Link to="/Secretariat" className="text-white hover:text-[#bd71e3] text-xl md:text-[1.2rem] font-ubuntu font-semibold md:font-bold">
                Secretariat
              </Link>
            </li>
            <li>
              <Link to="/Committees" className="text-white hover:text-[#bd71e3] text-xl md:text-[1.2rem] font-ubuntu font-semibold md:font-bold">
                Committees
              </Link>
            </li>
            <li>
              <Link to="/Sponsors" className="text-white hover:text-[#bd71e3] text-xl md:text-[1.2rem] font-ubuntu font-semibold md:font-bold">
                Sponsor Us
              </Link>
            </li>
            <li>
              <Link to="/Contact" className="text-white hover:text-[#bd71e3] text-xl md:text-[1.2rem] font-ubuntu font-semibold md:font-bold">
                Contact Us
              </Link>
            </li>
            <li>
              <Link to={'/profile'}>
                <img src={currentUser !== null ? `${currentUser.photoURL}` : 'https://i.pinimg.com/564x/f1/0f/f7/f10ff70a7155e5ab666bcdd1b45b726d.jpg'} alt="profile" className='h-10 w-10 rounded-full'/>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </NavbarContainer>
  );
};

// Styled components with dynamic styling based on scroll state
const NavbarContainer = styled.div<{ scrollNav: boolean }>`
  background-color: ${({ scrollNav }) => (scrollNav ? '#14041c  ' : 'transparent')};
  transition: background-color 0.3s ease;
  padding: 10px;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 1000000000000;
`;

export default Navbar;
