import React from 'react'
import styled from 'styled-components'

const About = () => {
  return (
   <AboutContainer className=' bg-[#290d35] mt-5'>

    <section className='about-section bg-[#290d35;] text-center items-center md:w-[65%] w-full m-auto'>

    <div className="about-heading md:gap-3  gap-2 flex flex-row justify-center text-center items-center">
            <h1 className='text-[#bd71e3] max-sm:text-[2.2em] max-lg:text-[5.3em] text-[3.2em] font-semibold'>What is</h1>
          <img src="assets/newlogo.png" alt="" className='w-[120px] md:w-[210px]'/>
          <span className='text-[#bd71e3]  max-sm:text-[2.1em] max-lg:text-[5.3em] text-[3.2em] font-semibold'>?</span>
        </div>
        
<p className="about-paragraph text-[white] md:text-[1.4em] max-sm:mt-4 max-md:mx-4 text-[1.1em] font-ubuntu">
उMUNg is an immersive educational platform where students step into the roles of diplomats or representatives to tackle global challenges in collaboration with delegates from different institutes. <br /> <br />
In this simulation, students represent a country or key figure in a committee, engaging in speeches, debates, and negotiations to form alliances, discuss policies, and draft resolutions to address global issues.
</p>
<div className="munDate text-white mt-7">
    <h2 className="startingHeading text-[#bd71e3] md:text-[3em] text-[1.6em] md:font-bold">The Conference will be held on</h2>
          <h1 className='md:text-[3.5em] text-[1.8em] font-semibold'>November <span className="munDateSpan">13–14, <span className='text-[#bd71e3]'>2024</span></span></h1>
        </div>
    </section>


 

   </AboutContainer>
  )
}

const AboutContainer = styled.div`
   /*About Section*/

   .about-section {
    
    font-family: 'Ubuntu', sans-serif;
  }

`
export default About
