import React, { useEffect, useState } from 'react';
import './App.css';
import Contact from './pages/Contact ';
import Sponsor from './pages/Sponsor ';
import Home from './pages/Home';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Secretariat from './pages/Secretariat';
import Layout from './layout/Layout';
import Committees from './pages/Committees';
import UserForm from './pages/Form';
import { useStateContext } from './components/Constants/ContextProvider';
import { Toaster } from 'react-hot-toast';
import axios from 'axios';
import Profile from './pages/Profile';
import Register from './pages/Register';
import Loader from './components/UI/Loader';

function App() {

  const {currentUser, studentData, setStudentData, setUmungData} = useStateContext()
  const [loading,setLoading] = useState<Boolean>(false)

  const setUser =async()=>{
    if(currentUser !== null ){
      setLoading(true)
      await axios.get(`https://student-data2024-eosin.vercel.app/api/students/getRequest?uid=${currentUser.uid!}`).then((res)=>setStudentData(res.data.StudentData)).catch((error)=>console.log(error))
      setLoading(false)
    }
  }
  const setUmung =async()=>{
    if(currentUser !== null ){
      setLoading(true)
      await axios.get(`https://student-data2024-eosin.vercel.app/api/umung/getStudentByUid?uid=${currentUser.uid!}`).then((res)=>setUmungData(res.data.CurrentStudent)).catch((error)=>console.log(error))
      setLoading(false)
    }
  }

  useEffect(()=>{
    setUser()
    setUmung()
  },[currentUser])

  return (
    
    <BrowserRouter>
    <div className='z-20'>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
      />
    </div>
   <Layout>
    {loading && <Loader/>}
      {loading === false && <MainDiv>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Home' element={<Home />} />
          <Route path='/Secretariat' element={<Secretariat /> } />
          <Route path='/Sponsors' element={<Sponsor />} />
          <Route path='/Contact' element = {<Contact />} />
          <Route path='/Committees' element={<Committees />} />
          <Route path='/profile' element={currentUser !== null ? <Profile />:<Home/>} />
          <Route path='/register' element={studentData !== null ? <Register />:<Home/>} />
          <Route path='/Form' element={studentData !== null ? <Home/> : <UserForm />} />
          </Routes>
      </MainDiv>}
   </Layout>
    </BrowserRouter>

  );
}

const MainDiv = styled.div`
background-color: #290d35;
`;

export default App;
