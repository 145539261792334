import React from 'react'
import styled from 'styled-components'
import Previous from '../components/sponsor/Previous'
import Contact from '../components/sponsor/Contact'
import Why from '../components/sponsor/Why'
import Footer from '../layout/Footer'
import { Link } from 'react-router-dom'

const Sponsor  = () => {
  return (
   <SponsorContainer>
<section className="home" id="home">
      <div className="max-width">
        <div className="home-content">
          <div className="committeeBanner-container1">
            <img src="assets/newlogo.png" className='z-[999]' alt="" />
            <div className="line2"></div>
            <h1>THIRD EDITION</h1>
          </div>
          <div className="text-2">
          Collaborate With <span className='text-[#bd71e3]'>Us</span>
          
          </div>
          <h1 className='max-sm:text-[1.7em] md:text-[2em] mt-5 p-1 px-2 rounded  hover:bg-[#290d35] font-semibold text-[#bd71e3] border-[#bd71e3] border-[2px] z-[99999999]'>
            <Link to="https://drive.google.com/file/d/1LN7T98v6Hwdsd2OOzrRvhP3Ldb-Nzfc6/view?usp=sharing" target="_blank">Download The Proposal</Link>
          
          </h1>
        </div>
        <div className="colored-cover"></div>
      </div>
    </section>
    <Why />
    <Previous/>
    <Contact/>
    <Footer/>
   </SponsorContainer>
  )
}

const SponsorContainer = styled.div`
.home {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("assets/spon.png") no-repeat center;
    /* background-color: #14404D; */
    height: 100vh;
    color: #000000ee;
    min-height: 50vh;
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Ubuntu', sans-serif;
    /* font-family: Bebas; */
}

.home .max-width {
    width: 100%;
    display: flex;
}

.home .home-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.home .home-content .text-2 {
    font-size: 5rem;
    font-weight: 800;
    position: relative;
    z-index: 999;
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-style: normal;
    text-align: center;
    justify-content: center;
    line-height: 1;
}

.home .home-content .text-3 {
    font-size: 3rem;
    text-align: center;
    justify-content: center;
    z-index: 999;
    color: white;
    font-weight: 600;
}

.home .home-content .text-2 span {
    color: #772891;
}

.proposalDownload {
    color: #000000;
    margin-top: 40px;
    font-size: 6vh;
    z-index: 999;
}

.colored-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000;
    opacity: 0.6;
    /* Adjust the opacity as needed */
}
.committeeBanner-container1 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 999;
}

.committeeBanner-container1 img {
    width: 180px;
}

.committeeBanner-container1 h1 {
    color: white;
    font-size: 1.8rem;
    transition: 0.8s ease-in-out;
}

.committeeBanner-container1 .line2 {
    margin: 20px;
    width: 4px;
    background-color: white;
    height: 50px;
}

@media screen  and (max-width:768px){
  .home .home-content .text-2 {
    font-size: 3.6rem;
    font-weight: 700;
    position: relative;
    z-index: 999;
    color: white;
    font-style: normal;
    text-align: center;
    justify-content: center;
}

.home .home-content .text-3 {
    font-size: 2rem;
    text-align: center;
    justify-content: center;
    z-index: 999;
    color: white;
    font-weight: 600;
}

.home .home-content .text-2 span {
    color: #9f13ce;
} 


.committeeBanner-container1 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 999;
}

.committeeBanner-container1 img {
    width: 150px;
}

.committeeBanner-container1 h1 {
    color: white;
    font-size: 1.7rem;
    transition: 0.8s ease-in-out;
}

.committeeBanner-container1 .line2 {
    margin: 10px;
    width: 4px;
    background-color: white;
    height: 50px;
}
}

.committeeBanner-container1 {
  
    justify-content: center;
    text-align: center;
    
}


`


export default Sponsor 
